import React from "react"
import { AuthStateHook } from "./src/hooks/AuthState"

import "./src/global.css"

export const wrapRootElement = ({ element }) => {
    return <AuthStateHook>{element}</AuthStateHook>
}

export const shouldUpdateScroll = ({ routerProps, prevRouterProps }) => {
    if (!routerProps || !prevRouterProps) {
        return
    }

    if (
        routerProps &&
        routerProps.location &&
        routerProps.location.hash &&
        routerProps.location.pathname === prevRouterProps.location.pathname
    ) {
        const hash = routerProps.location.hash.replace("#", "").trim()
        if (hash === "_") {
            return false
        }
    }

    return true
}
