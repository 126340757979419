import React, { useContext, useState, createContext, useEffect } from "react"
export const PERSIST_USERDATA_TAG = "AppState"

const AuthStateContext = createContext(false)

let theTimeout = null

export const AuthStateHook = ({ children }) => {
    const [isLogged, setIsLogged] = useState(null)
    const [token, setToken] = useState(null)

    useEffect(() => {
        const url = process.env.GATSBY_DOZEN_PRODUCT_IFRAME_URL

        window.addEventListener("message", messageHandler, false)

        function messageHandler(event) {
            if (
                event.origin !== url ||
                typeof event.data.dozenAuth === "undefined"
            ) {
                return
            }

            setIsLogged(event.data.dozenAuth)
            setToken(event.data.dozenToken)

            if (window?.dataLayer && event.data.dozenAuth) {
                let loginEventParams = {
                    event: "login",
                }

                if (event.data.investmentsNumber) {
                    loginEventParams.investmentsNumber =
                        event.data.investmentsNumber
                    loginEventParams.lastInvestmentDaysAgo =
                        event.data.lastInvestmentDaysAgo
                }

                window.dataLayer.push(loginEventParams)
            }
        }

        const iframe = window.document.createElement("iframe")

        iframe.src = url + "/auth.html"
        iframe.style.display = "none"
        iframe.id = "product-iframe"
        window.document.querySelector("body").append(iframe)

        return () => {
            iframe.remove()
            window.removeEventListener("message", messageHandler)
        }
    }, [])

    useEffect(() => {
        if (isLogged === null) {
            theTimeout = setTimeout(() => {
                if (isLogged === null) {
                    setIsLogged(false)
                }
            }, 6000)
        }

        return () => {
            if (theTimeout) {
                window.clearTimeout(theTimeout)
                theTimeout = null
            }
        }
    }, [isLogged])

    return (
        <AuthStateContext.Provider value={{ isLogged, token }}>
            {children}
        </AuthStateContext.Provider>
    )
}

export const AuthStateConsumer = AuthStateContext.Consumer
export function useAuthState() {
    return useContext(AuthStateContext)
}
