const React = require("react")

const { LangProvider } = require("./context/LangContext")
const { GlobalProvider } = require("./context/GlobalContext")

const LangWrapper = require("./context/LangWrapper").default

const { getDefaultOptionsByPathname } = require(`./utils/options`)
const { getLayoutData } = require(`./utils/page`)

exports.wrapRootElement = ({ element }, themeOptions) => {
  const options = getDefaultOptionsByPathname(
    themeOptions,
    window.location.pathname
  )

  return <LangProvider options={options}>{element}</LangProvider>
}

exports.wrapPageElement = ({ element, props }, themeOptions) => {
  const { pageContext, path } = props
  const layoutData = getLayoutData(pageContext, themeOptions, path)

  return (
    <LangWrapper
      language={pageContext.language || themeOptions.primaryLanguage}
    >
      <GlobalProvider value={layoutData}>{element}</GlobalProvider>
    </LangWrapper>
  )
}
